export default {
  productFilterNone: { id: 'product_filter_none', defaultMessage: 'All' },
  productFilterSearch: {
    id: 'product_filter_search',
    defaultMessage: 'Search for {facet}',
  },
  clear: {
    id: 'searchbar_clear',
    defaultMessage: 'clear',
  },
}
