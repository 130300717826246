<template>
  <section class="flex flex-col gap-16">
    <div class="flex items-center gap-x-8">
      <h3 v-if="variant === 'default' || displayInfos" class="body-1-bold">
        {{ i18n(translations.priceTitle) }}
      </h3>
      <RevButtonBase
        v-if="clearable && (input[0] > 0 || input[1] < max)"
        class="text-action-default-hi body-2-bold ml-auto px-12 py-2"
        size="small"
        variant="primary"
        @click="clear"
        >{{ i18n(translations.clear) }}</RevButtonBase
      >
    </div>

    <div>
      <Graph
        class="h-[100px]"
        data-test="graph"
        :items="prices"
        :max-selected="input[1]"
        :min-selected="input[0]"
        :step
      />
      <RevRange
        v-model="input"
        class="mx-2"
        data-test="range"
        :disabled="!ready"
        :label="[
          i18n(translations.minPrice, { currency }),
          i18n(translations.maxPrice, { currency }),
        ]"
        :max
        :step
      />
    </div>

    <div>
      <div class="flex flex-row justify-between py-3">
        <PriceInput
          id="min-price"
          v-model="input[0]"
          class="w-96"
          :label="i18n(translations.minPrice, { currency })"
          :max
          :min="0"
        />
        <PriceInput
          id="max-price"
          v-model="input[1]"
          class="w-96"
          :label="i18n(translations.maxPrice, { currency })"
          :max
          :min="0"
        />
      </div>
      <span class="body-2 mt-12">
        {{
          i18n(translations.avgPrice, {
            amount: Math.ceil(Number(avgPrice)).toString(),
            currency,
            number: Math.ceil(Number(avgPrice)),
          })
        }}
      </span>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'

import { Currency } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevRange } from '@ds/components/Range'

import Graph from './Graph.vue'
import translations from './PriceFilter.translations'
import PriceInput from './PriceInput.vue'

const i18n = useI18n()

const { max } = defineProps<{
  currency: Currency
  avgPrice: number
  prices: number[]
  step: number
  max: number
  variant: 'default' | 'full'
  displayInfos?: boolean
  clearable?: boolean
}>()

const input = defineModel<[number, number]>({
  required: true,
})

const ready = ref(false)

const { trackClick } = useTracking()

onMounted(() => {
  ready.value = true
})

watch(input, (newInput, oldInput) => {
  if (newInput[0] !== oldInput[0]) {
    trackClick({
      name: 'minSlider',
      zone: 'priceFilter',
      value: newInput[0],
    })
  }
  if (newInput[1] !== oldInput[1]) {
    trackClick({
      name: 'maxSlider',
      zone: 'priceFilter',
      value: newInput[1],
    })
  }
})

function clear() {
  input.value = [0, max]
}
</script>
