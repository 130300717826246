export default {
  erase: {
    id: 'filters_erase',
    defaultMessage: 'Erase',
  },
  priceTitle: {
    id: 'landing_price_filter_title',
    defaultMessage: 'Price',
  },
  avgPrice: {
    id: 'landing_price_filter_avg',
    defaultMessage: 'The average price is { amount, number, currency }',
  },
  resetFilter: {
    id: 'reset_filter',
    defaultMessage: 'reset this filter',
  },
  validate: {
    id: 'filters_results',
    defaultMessage: `See {count, plural,
      one {# product}
      other {# products}
    }`,
  },
  toFacets: { id: 'product_filter_to_facets', defaultMessage: 'Back' },
  minPrice: {
    id: 'landing_price_filter_min',
    defaultMessage: 'Min ({currency})',
  },
  maxPrice: {
    id: 'landing_price_filter_max',
    defaultMessage: 'Max ({currency})',
  },
  clear: {
    id: 'searchbar_clear',
    defaultMessage: 'clear',
  },
}
